import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../../components/Layout';
import CFS from '../../../components/molecules/CFS';
import { INSCRIPCION_ROUTE } from '../../../constants/routes';

import '../../../assets/styles/pages/descuentos.scss';

const Descuentos = () => (
  <Layout
    title="Cursos ELE Barcelona « Descuentos y Ofertas"
    description="En ELE Barcelona ofrecemos un descuento si te inscribes con 6 semanas de antelación al comienzo del curso"
  >
    <div className="descuentos">
      <CFS
        title="Descuentos"
        description="En ELE Barcelona nos preocupamos por tu bolsillo. Aquí tienes varios descuentos en nuestros cursos más populares:"
      />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row extra-info-cards">
            <div className="col-md-6 col-12">
              <div className="course-card">
                <div className="card__header">
                  <h3 className="header__title">Descuento por reserva anticipada</h3>
                </div>
                <div className="card__center">
                  <div className="left">
                    <h4 className="left__title">Ahorra</h4>
                    <h4 className="left__price">150€</h4>
                  </div>
                </div>
                <div className="card__body">
                  <ul className="card__list">
                    <li>Paga el importe total del curso con un mes de antelación y ahorra 150€</li>
                    <li>Descuento válido para el curso FELE Intensivo</li>
                    <li>Precio final que incluye curso, certificado y tasa de examen</li>
                  </ul>
                </div>
                <div className="card__bottom">
                  <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                    Inscríbete ahora
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="course-card">
                <div className="card__header">
                  <h3 className="header__title">Trae un/a amigo/a</h3>
                </div>
                <div className="card__center">
                  <div className="left">
                    <h4 className="left__title">Descuento de</h4>
                    <h4 className="left__price">
                      100€ <span>cada uno/a</span>
                    </h4>
                  </div>
                </div>
                <div className="card__body">
                  <ul className="card__list">
                    <li>
                      Invita a un/a amigo/a a hacer el curso contigo y los/as dos recibiréis un
                      descuento de 100€ del precio total del curso
                    </li>
                    <li>Descuento válido para el curso FELE Intensivo</li>
                    <li>Estudia en el mismo mes que tu amigo/a</li>
                  </ul>
                </div>
                <div className="card__bottom">
                  <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                    Inscríbete ahora
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Descuentos;
